import React, { useContext, useEffect, useState } from "react";
import { Button,  Form, InputGroup, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  AssignEmployeeofficeitemModal,
  Officeinventoryitemlist,
} from "./Modal";
import { GlobalContext } from "../../Context/GlobalState";
import { billpunchbasicauth } from "../../utils/Credentials";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";

const ItemsofficeInventory = () => {
  const [selectedItem, setSelectedItem] = useState(null);
  const { password, username } = billpunchbasicauth;
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [selectedItemIdForModal, setSelectedItemIdForModal] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [itemlist, setitemlist] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [itemDetails, setitemDetails] = useState([]); 
  const filteredItems = itemDetails.filter((item) => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    return (
      item.item_name.toLowerCase().includes(lowerCaseQuery) ||
      item.company_name.toLowerCase().includes(lowerCaseQuery) ||
      item.product_serial.toLowerCase().includes(lowerCaseQuery) ||
      item.device_id.toLowerCase().includes(lowerCaseQuery) ||
      item.product_id.toLowerCase().includes(lowerCaseQuery) ||
      (item.assignedEmployee || "").toLowerCase().includes(lowerCaseQuery)
    );
  });
  const handleShowModal = (id) => {
    setSelectedItemIdForModal(id);
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowaddlistModal = () => {
    setitemlist(true);
  };

  const handleCloselistModal = () => {
    setitemlist(false);
  };
  const Gettabledata = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic" + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        firmid: user.firm_id,
        fk_deviceid: selectedItem ? selectedItem.id :0,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetInternalInventory`,
        requestOptions
      );

      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }

      const data = await res.json();
      if (data.status === "0") {
        setitemDetails(data.data);
      } else {
        console.error("API response error:", data.msg);
      }
    } catch (error) {
      console.error("Fetch error:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };
  useEffect(() => {
    if (selectedItem) {
      Gettabledata();
    }
  }, [selectedItem]);
  useEffect(() => {
    const storedItem = sessionStorage.getItem("selectedItem");
    if (storedItem) {
      setSelectedItem(JSON.parse(storedItem));
    }
  }, []);
  return (
    <>
      <div className="m-3">
        <div className="row">
          <div className="col">
          <h4>
          {selectedItem
                ? `${selectedItem.name}`
                : "No item selected"}

            </h4>
          </div>

          <div className="col d-flex justify-content-end">
          <div className="px-2">
          <InputGroup className="">
            <Form.Control
              type="text"
              placeholder="Search items..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              style={{height:"2rem"}}
            />
          </InputGroup>
        </div>
            <div className="px-2">
              <Button size="sm" onClick={handleShowaddlistModal}>
                Add Items
              </Button>
            </div>
            <Link to="/retailer/Office_inventory">
              <Button className="btn-sm">Back</Button>
            </Link>
          </div>
        </div>

        <Table striped bordered hover className="mt-">
      <thead>
        <tr>
          <th
            className="text-center"
            style={{
              color: "#0074D9",
              background: "#fff",
            }}
          >
            Item Name
          </th>
          <th
            className="text-center"
            style={{
              color: "#0074D9",
              background: "#fff",
            }}
          >
            Company
          </th>
          <th
            className="text-center"
            style={{
              color: "#0074D9",
              background: "#fff",
            }}
          >
            Serial Number
          </th>
          <th
            className="text-center"
            style={{
              color: "#0074D9",
              background: "#fff",
            }}
          >
            Device ID
          </th>
          <th
            className="text-center"
            style={{
              color: "#0074D9",
              background: "#fff",
            }}
          >
            Product ID
          </th>
          <th
            className="text-center"
            style={{
              color: "#0074D9",
              background: "#fff",
            }}
          >
          Defects
          </th>
          <th
            className="text-center"
            style={{
              color: "#0074D9",
              background: "#fff",
            }}
          >
         Password
          </th>
          <th
            className="text-center"
            style={{
              color: "#0074D9",
              background: "#fff",
            }}
          >
            Assigned Employee
          </th>
          <th
            className="text-center"
            style={{
              color: "#0074D9",
              background: "#fff",
            }}
          >
            Actions
          </th>
        </tr>
      </thead>
      <tbody style={{ background: "#fff" }}>
  {itemDetails.length === 0 ? (
    <tr>
      <td colSpan="7" className="text-center" style={{ background: "#fff" }}>
        No data found
      </td>
    </tr>
  ) : (
    itemDetails.map((detail, index) => (
      <tr key={index}>
        <td
          className="text-center align-middle"
          style={{ background: "#fff" }}
        >
          {detail.item_name}
        </td>
        <td
          className="text-center align-middle"
          style={{ background: "#fff" }}
        >
          {detail.company_name}
        </td>
        <td
          className="text-center align-middle"
          style={{ background: "#fff" }}
        >
          {detail.serial_number}
        </td>
        <td
          className="text-center align-middle"
          style={{ background: "#fff" }}
        >
          {detail.device_id}
        </td>
        <td
          className="text-center align-middle"
          style={{ background: "#fff" }}
        >
          {detail.product_id}
        </td>
        <td
          className="text-center align-middle"
          style={{ background: "#fff" }}
        >
          {detail.defects}
        </td>
        <td
          className="text-center align-middle"
          style={{ background: "#fff" }}
        >
          {detail.password}
        </td>
        <td
          className="text-center align-middle"
          style={{ background: "#fff" }}
        >
          {detail.name || "N/A"}
        </td>
        <td
          className="text-center align-middle"
          style={{ background: "#fff" }}
        >
          <Button
            size="sm"
            variant="info"
            onClick={() => handleShowModal(detail.id)}
          >
            Assign 
          </Button>
        </td>
      </tr>
    ))
  )}
</tbody>

    </Table>

        <AssignEmployeeofficeitemModal
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          selectedItemId={selectedItem ? selectedItem.id : null}
          Gettabledata={Gettabledata}
          selectedItemIdForModal={selectedItemIdForModal}
        />
        <Officeinventoryitemlist
          showModal={itemlist}
          handleCloseModal={handleCloselistModal}
          selectedItemId={selectedItem ? selectedItem.id : null}
          Gettabledata={Gettabledata}
        />
      </div>
    </>
  );
};

export default ItemsofficeInventory;
