import React, { useEffect, useState } from 'react';

const ActiveUserCount = () => {
    const [activeUserCount, setActiveUserCount] = useState(0);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchActiveUserCount = async () => {
            try {
                const response = await fetch('https://api.goseo.in/api/Seo/active-user-count');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setActiveUserCount(data.activeUserCount);
            } catch (error) {
                setError(error.message);
            }
        };

        fetchActiveUserCount();

        const intervalId = setInterval(fetchActiveUserCount, 30000); 

        return () => clearInterval(intervalId); 
    }, []);

    return (
        <div>
            {/* <h3>Current Active Users: {error ? `Error: ${error}` : activeUserCount}</h3> */}
        </div>
    );
};

export default ActiveUserCount;
